


































































import Vue from "vue";

export default Vue.extend({
  name: "Anuncios",
  components: {
  },
  data: () => ({
    error: "",
    reveal: false,
  }),
  props: {
    merchant: {},
  },
  methods: {
    async consent() {
      const sessionToken = localStorage.getItem("session");
      const headers = new Headers({
        "Content-Type": "application/json",
        "x-yoyo-key": sessionToken || "",
      });
      const resp = await fetch(
          `${process.env.VUE_APP_API_URL}/merchants/flags`,
          {
            method: "PUT",
            headers,
            body: JSON.stringify({
              feesConsent: true
            }),
          }
      );

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        alert("Gracias por aceptar los nuevos cambios, puedes solicitar desembolso en el app móvil.")
      } else {
        this.error = errorMessage;
      }
    },
  },
});
